import { useMemo } from 'react'

import { emptyAddress } from '@app/utils/constants'

import { useAccountSafely } from './useAccountSafely'

export const useIsBooker = (booker: string | undefined) => {
  const { address } = useAccountSafely()
  const isBooker = useMemo(() => {
    if (!address || !booker) {
      return false
    }
    if (booker !== emptyAddress && booker !== address) {
      return true
    }
    return false
  }, [address, booker])
  return isBooker
}
