import { useNetwork } from 'wagmi'

import { SUPPORT_NETWORK_CHAIN_IDS } from '@app/utils/constants'

export enum ChainId {
  MAINNET = 1,
  GOERLI = 5,
  BASE = 8453,
}

export const useChainId = (): number => {
  const { chain } = useNetwork()
  if (chain) {
    return chain.id
  }
  return SUPPORT_NETWORK_CHAIN_IDS[0]
}
