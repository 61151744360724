import {
  BaseRegistrarImplementation,
  ERC20,
  ERC721,
  ETHRegistrarController,
} from '@myclique/awnsjs/generated/index'
import { useEffect, useState } from 'react'
import { useChainId, useContract, useProvider, useSigner } from 'wagmi'

import { useEns } from '@app/utils/EnsProvider'
import AvatarManagerAbi from '@app/utils/abis/AvatarManager.json'
import ERC721_ABI from '@app/utils/abis/ERC721.json'
import erc20Abi from '@app/utils/abis/erc20.json'
import multicallAbi from '@app/utils/abis/multicall.json'
import Nft6551_ABI from '@app/utils/abis/nft6551.json'
import {
  AvatarManager,
  ERC721 as ERC721Type,
  Erc20 as Erc20Type,
  Nft6551 as Nft6551Type,
} from '@app/utils/abis/types'
import {
  AWNS_PICTURE_ADDRESS,
  SUPPORT_NETWORK_CHAIN_IDS,
  erc20ContractAddress,
} from '@app/utils/constants'

export const useEthRegistrarControllerContract = () => {
  const [contract, setContract] = useState<ETHRegistrarController>()
  const ens = useEns()

  const signer = useSigner()
  useEffect(() => {
    const func = async () => {
      const ethRegistrarController = await ens.contracts?.getEthRegistrarController()
      if (signer.data) {
        const controller = ethRegistrarController?.connect(signer.data)
        setContract(controller)
        return
      }
      setContract(ethRegistrarController)
    }
    func()
  }, [ens.contracts, signer.data])

  return contract
}

export const useErc20Contract = (contractAddress?: string) => {
  const contractsAddress = contractAddress || erc20ContractAddress
  const [contract, setContract] = useState<ERC20>()
  const ens = useEns()
  const signer = useSigner()

  useEffect(() => {
    const func = async () => {
      const Erc20Controller = await ens.contracts?.getEth20Controller(undefined, contractsAddress)
      if (signer.data) {
        const controller = Erc20Controller?.connect(signer.data)
        setContract(controller)
        return
      }
      setContract(Erc20Controller)
    }
    func()
  }, [contractsAddress, ens.contracts, signer.data])
  return contract
}

export const useErc721Contract = (ContractAddress: string) => {
  const [contract, setContract] = useState<ERC721>()
  const ens = useEns()
  const signer = useSigner()

  useEffect(() => {
    if (!ContractAddress) return
    const func = async () => {
      const Erc721Controller = await ens.contracts?.getEth721Controller(undefined, ContractAddress)
      if (signer.data) {
        const controller = Erc721Controller?.connect(signer.data)
        setContract(controller)
        return
      }
      setContract(Erc721Controller)
    }
    func()
  }, [ContractAddress, ens.contracts, signer.data])
  return contract
}

export const useBaseRegistrarContract = () => {
  const [contract, setContract] = useState<BaseRegistrarImplementation>()
  const ens = useEns()
  const signer = useSigner()
  useEffect(() => {
    const func = async () => {
      const baseRegistrarController = await ens.contracts?.getBaseRegistrar()
      if (signer.data) {
        const controller = baseRegistrarController?.connect(signer.data)
        setContract(controller)
        return
      }
      setContract(baseRegistrarController)
    }
    func()
  }, [ens.contracts, signer.data])
  return contract
}

export function useTokenContract(address?: string) {
  const provider = useProvider()
  const { data: signer } = useSigner()
  return useContract({
    address,
    abi: erc20Abi,
    signerOrProvider: signer || provider,
  }) as Erc20Type | null
}

export function useClaimToken1155Contract() {
  const provider = useProvider()
  const chainId = useChainId()
  const { data: signer } = useSigner()
  return useContract({
    address: AWNS_PICTURE_ADDRESS[chainId],
    abi: AvatarManagerAbi,
    signerOrProvider: signer || provider,
  }) as AvatarManager | null
}
const MULTICALL_ADDRESS: { [x in number]: string } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  1: '0x1F98415757620B543A52E61c46B32eB19261F984',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  5: '0x1F98415757620B543A52E61c46B32eB19261F984',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  8453: '0x8E160Da72740810D1852AA6782fD4Fd6f39d1f2E',
}

export function useInterfaceMulticall(chainId?: number) {
  const provider = useProvider()
  return useContract({
    address: MULTICALL_ADDRESS[chainId || SUPPORT_NETWORK_CHAIN_IDS[0]],
    signerOrProvider: provider,
    abi: multicallAbi,
  })
}

export function useNft6551Contract(address: string | undefined) {
  const provider = useProvider()
  const { data: signer } = useSigner()

  return useContract({
    address,
    abi: Nft6551_ABI,
    signerOrProvider: signer || provider,
  }) as Nft6551Type
}

export function useERC721Contract(address: string | undefined) {
  const provider = useProvider()
  const { data: signer } = useSigner()

  return useContract({
    address,
    abi: ERC721_ABI,
    signerOrProvider: signer || provider,
  }) as ERC721Type
}
