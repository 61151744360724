import { Box } from '@mui/material'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

import { Button, Dropdown, Typography } from '@ensdomains/thorin'
import { DropdownItem } from '@ensdomains/thorin/dist/types/components/molecules/Dropdown/Dropdown'

import BotIconSvg from '@app/assets/BotIcon.svg'
import GWeiSvg from '@app/assets/GWei.svg'
import STPSvg from '@app/assets/StpLogo.svg'
import StpLogoWhiteSvg from '@app/assets/StpLogo_white.svg'
import { DisconnectButton } from '@app/components/@atoms/RouteItem/RouteItem'
import { Hamburger } from '@app/components/@molecules/Hamburger/Hamburger'
import { ChainLogo, ENVChainIdMappingDomain, ENVChainIds, ENVChainList } from '@app/constants/chain'
import { ROUTES } from '@app/hooks/routes'
import { useAccountSafely } from '@app/hooks/useAccountSafely'
import { useChainId } from '@app/hooks/useChainId'
import useGasPrice from '@app/hooks/useGasPrice'
import { LogoAndLanguage, StyledENS, StyledLeadingHeading } from '@app/pages'
import { useBreakpoint } from '@app/utils/BreakpointProvider'
import { SUPPORT_NETWORK_CHAIN_IDS } from '@app/utils/constants'
import { makeDisplay } from '@app/utils/currency'

import { HeaderConnect } from './ConnectButton'

const AiChat = dynamic(() => import('@app/components/pages/AiChat'), { ssr: false })

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const HeaderLayout = styled(Row)`
  height: 80px;
  padding: 0 50px;
  justify-content: space-between;
  position: relative;
  z-index: 9;
`
const HeaderLeft = styled(Row)`
  gap: 12px;
  cursor: pointer;
`
const Line = styled.div`
  width: 2px;
  height: 20px;
  background: #dae4f0;
`
export const InterText = styled(Typography)<{ $textColor?: string; $w?: number }>`
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.$w || 400};
  line-height: 20px;
  color: ${(props) => props.$textColor || '#3f5170'};
`
const HeaderNav = styled(Row)<{ $color: string }>`
  gap: 60px;
  flex: 0.8;

  & p {
    color: ${(props) => props.$color};
  }
`
const GWeiBox = styled(Row)`
  padding: 8px 15px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d4d7e2;
  background: #fff;
`

const ChainButton = styled('div')`
  border-radius: 8px;
  border: 1px solid #d4dce2;
  background: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  .exchange {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

const AIBotBox = styled(Box)(() => ({
  height: 36,
  padding: '0 10px',
  borderRadius: '8px',
  alignItems: 'center',
  border: '2px solid #D6FFB6',
  backgroundColor: '#A7F46A',
  gap: '6px',
  display: 'flex',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ':hover': {
    opacity: 0.9,
  },
}))

export function ChainSelect() {
  const chainId = useChainId()
  const { address } = useAccount()
  const { switchNetworkAsync } = useSwitchNetwork()

  if (typeof window === 'undefined') return null

  return (
    <Dropdown
      width={170}
      align="left"
      items={ENVChainList.map(
        (item) =>
          ({
            label: item.name,
            color: 'text',
            onClick: () => {
              if (item.id === chainId) return
              if (!address)
                window.location.href =
                  ENVChainIdMappingDomain[item.id as keyof typeof ENVChainIdMappingDomain]
              if (switchNetworkAsync) switchNetworkAsync(item.id)
            },
            // eslint-disable-next-line @next/next/no-img-element
            icon: ChainLogo[item.id as keyof typeof ChainLogo],
          } as DropdownItem),
      )}
    >
      <ChainButton>
        <svg
          className="exchange"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.5" cy="6.5" r="6" fill="#0049C6" stroke="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.3204 3.90846C5.48357 3.70064 5.48357 3.36369 5.3204 3.15587C5.15722 2.94804 4.89266 2.94804 4.72948 3.15587L3.1224 5.20265C3.00289 5.35485 2.96714 5.58374 3.03182 5.7826C3.09649 5.98145 3.24885 6.11111 3.41785 6.11111H9.58191C9.81268 6.11111 9.99976 5.87285 9.99976 5.57895C9.99976 5.28504 9.81268 5.04678 9.58191 5.04678H4.42661L5.3204 3.90846ZM7.67963 9.09154C7.51645 9.29936 7.51645 9.63631 7.67963 9.84413C7.84281 10.052 8.10737 10.052 8.27055 9.84413L9.87763 7.79735C9.99713 7.64515 10.0329 7.41626 9.96821 7.2174C9.90353 7.01855 9.75117 6.88889 9.58217 6.88889H3.41811C3.18734 6.88889 3.00027 7.12715 3.00027 7.42105C3.00027 7.71496 3.18734 7.95322 3.41811 7.95322H8.57341L7.67963 9.09154Z"
            fill="white"
          />
        </svg>
        {ChainLogo[chainId as keyof typeof ChainLogo]}
      </ChainButton>
    </Dropdown>
  )
}

const Page = () => {
  const { gasPrice } = useGasPrice()
  const router = useRouter()
  const breakpoints = useBreakpoint()
  const { address } = useAccountSafely()
  const { chain: currentChain } = useNetwork()
  const { openConnectModal } = useConnectModal()
  const isMdDown = useMemo(() => {
    return !breakpoints.md
  }, [breakpoints.md])
  const [openAiChat, setOpenAiChat] = useState<boolean>(false)

  const isLight = useMemo(() => {
    return router.asPath === '/' || router.asPath.includes(ROUTES.nftAccount.deploy)
  }, [router.asPath])

  useEffect(() => {
    if (!address) setOpenAiChat(false)
  }, [address])

  const isTrueChain = useMemo(() => {
    if (
      currentChain &&
      !SUPPORT_NETWORK_CHAIN_IDS.includes(currentChain.id) &&
      !ENVChainIds.includes(currentChain.id)
    )
      return false
    return true
  }, [currentChain])

  return (
    <>
      {isMdDown ? (
        <StyledLeadingHeading>
          <LogoAndLanguage>
            <StyledENS as={isLight ? StpLogoWhiteSvg : STPSvg} />
          </LogoAndLanguage>
          <div style={{ display: 'flex', gap: 5 }}>
            {address ? (
              <>
                <ChainSelect />
                <DisconnectButton />
              </>
            ) : (
              <Button
                style={{
                  width: '90px',
                  height: '30px',
                  fontSize: '12px',
                }}
                onClick={() => openConnectModal?.()}
              >
                Connect
              </Button>
            )}
            <Hamburger />
          </div>
        </StyledLeadingHeading>
      ) : (
        <HeaderLayout>
          <HeaderLeft onClick={() => router.push('/')}>
            {isLight ? <StpLogoWhiteSvg /> : <STPSvg />}
            <Line />
            <InterText style={{ fontWeight: 700 }} $textColor={isLight ? '#fff' : '#3f5170'}>
              AWNS
            </InterText>
          </HeaderLeft>
          <HeaderNav $color={isLight ? '#fff' : '#3f5170'}>
            <Link href="/">
              <InterText
                as="a"
                $textColor={isLight ? '#fff' : '#3f5170'}
                style={{ cursor: 'pointer' }}
              >
                Explore
              </InterText>
            </Link>
            {/* <Explore /> */}
            <Link href="/my/names">
              <InterText
                as="a"
                $textColor={isLight ? '#fff' : '#3f5170'}
                style={{ cursor: 'pointer' }}
              >
                AW Wallet
              </InterText>
            </Link>
            <Link href="/rewards">
              <InterText
                as="a"
                $textColor={isLight ? '#fff' : '#3f5170'}
                style={{ cursor: 'pointer' }}
              >
                Referral Rewards
              </InterText>
            </Link>
            {/* <a href="https://www.myclique.io/daos" target="_blank" rel="noreferrer">
          <InterText as="a" $textColor={isLight ? '#fff' : '#3f5170'} style={{ cursor: 'pointer' }}>
            Clique
          </InterText>
        </a> */}

            <InterText
              as="a"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  'https://stpdao.gitbook.io/whitepaper/autonomous-worlds-identity/awns-aw-name-service/how-to-guide',
                  '_blank',
                )
              }
              $textColor={isLight ? '#fff' : '#3f5170'}
            >
              Help
            </InterText>
          </HeaderNav>
          <HeaderLeft>
            {address && isTrueChain && (
              <AIBotBox onClick={() => setOpenAiChat(true)}>
                <BotIconSvg />
                <Typography
                  style={{
                    color: '#233657',
                    fontSize: 14,
                  }}
                >
                  AI Agent
                </Typography>
              </AIBotBox>
            )}
            <ChainSelect />
            <GWeiBox onClick={() => window.open('https://etherscan.io/gastracker', '_blank')}>
              <GWeiSvg />
              <InterText style={{ fontWeight: 500 }}>
                {gasPrice ? makeDisplay(gasPrice, undefined, 'Gwei', 9) : '-- Gwei'}
              </InterText>
            </GWeiBox>
            <HeaderConnect />
          </HeaderLeft>
          {address && isTrueChain && (
            <AiChat openModal={openAiChat} onClose={() => setOpenAiChat(false)} />
          )}
        </HeaderLayout>
      )}
    </>
  )
}
export default Page
