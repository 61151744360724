/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Box, Typography } from '@mui/material'
import { Dispatch, ForwardedRef, MouseEvent, SetStateAction, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Input, MagnifyingGlassSVG, mq } from '@ensdomains/thorin'

import SearchIconSvg from '@app/assets/search-icon.svg'

const SearchInputWrapper = styled.div<{ $size: 'medium' | 'extraLarge' }>(
  ({ theme, $size }) => css`
    z-index: 1;
    /* box-shadow: ${theme.boxShadows['0.25']}; */
    /* border-radius: ${theme.radii['2.5xLarge']}; */
    /* border-color: ${theme.colors.border}; */
    width: 100%;
    position: relative;
    &,
    & div,
    & div:focus-within {
      height: 52px;
      background-color: transparent;
      border-radius: none;
      border-color: transparent;
      border-width: 0;
    }

    /* & input::placeholder {
      color: ${theme.colors.greyPrimary};
      font-weight: ${theme.fontWeights.bold};
    } */
    ${$size === 'medium' &&
    css`
      max-width: ${theme.space['96']};
      box-shadow: none;
      border-radius: ${theme.radii.full};
      /* & input::placeholder {
        color: ${theme.colors.greyPrimary};
        font-weight: ${theme.fontWeights.normal};
      } */
    `}
  `,
)

const StyledInputParent = (size: 'medium' | 'extraLarge') =>
  css(
    ({ theme }) => css`
      border-radius: ${theme.radii.full};
      background-color: ${theme.colors.backgroundSecondary};
      transition: background-color 0.35s ease-in-out;
      ${size === 'medium' &&
      css`
        & > div {
          border-radius: ${theme.radii.full};
          input {
            padding-left: ${theme.space['12']};
          }
        }
      `}/* &:focus-within {
        background-color: ${theme.colors.backgroundPrimary};
        & input::placeholder {
          color: transparent;
        }
      } */
    `,
  )

const MagnifyingGlassIcon = styled.svg(
  ({ theme }) => css`
    width: ${theme.space['4']};
    height: ${theme.space['4']};
  `,
)
const SearchInputStyle = styled(Input)`
  padding-left: 45px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 80px;
  border: 2px solid #4f4f4f;
  background: #262629;
  backdrop-filter: blur(7px);
  caret-color: #fff;
  padding-right: 100px;
  &::placeholder {
    background: #b2b2b2;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    background-clip: text;
    color: transparent;
    line-height: normal;
    opacity: 0.5;
  }
  ${mq.sm.max(css`
    &::placeholder {
      font-size: 12px;
    }
  `)}
`
const SearchIconStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  opacity: 0.5;
`
const SearchButtonStyle = styled(Box)`
  height: 36px !important;
  width: 87px;
  position: absolute !important;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 80px;
  border: 1px solid #ffffff29;
  background: linear-gradient(0deg, #595959, #545454),
    radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.34) 0%, rgba(255, 255, 255, 0) 100%);
  & span {
    user-select: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #fff 75%);
    background-clip: text;
    color: transparent;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }
  & :hover {
    opacity: 0.7;
  }
`

export const SearchInputBox = forwardRef(
  (
    {
      size = 'extraLarge',
      input,
      setInput,
      containerRef,
      handleSearch,
    }: {
      size?: 'medium' | 'extraLarge'
      input: string
      setInput: Dispatch<SetStateAction<string>>
      containerRef: ForwardedRef<HTMLDivElement>
      handleSearch: () => void
    },
    ref,
  ) => {
    const { t } = useTranslation('awns_common')
    return (
      <SearchInputWrapper ref={containerRef} $size={size}>
        <SearchInputStyle
          size={size}
          label={t('search.label')}
          hideLabel
          placeholder={t('search.placeholder')}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          ref={ref as any}
          // clearable
          autoComplete="off"
          autoCorrect="off"
          parentStyles={StyledInputParent(size)}
          icon={size === 'medium' ? <MagnifyingGlassIcon as={MagnifyingGlassSVG} /> : undefined}
          spellCheck="false"
          data-testid="search-input-box"
        />
        <SearchIconStyle>
          <SearchIconSvg />
        </SearchIconStyle>
        <SearchButtonStyle>
          <Typography component="span" onClick={handleSearch}>
            Search
          </Typography>
        </SearchButtonStyle>
      </SearchInputWrapper>
    )
  },
)

export const FakeSearchInputBox = forwardRef(
  (
    {
      size = 'extraLarge',
      onClick,
    }: {
      size?: 'medium' | 'extraLarge'
      onClick: (e: MouseEvent<HTMLInputElement>) => void
    },
    ref,
  ) => {
    const { t } = useTranslation('common')
    return (
      <SearchInputWrapper $size={size}>
        <Input
          size={size}
          label={t('search.label')}
          hideLabel
          placeholder={t('search.placeholder')}
          ref={ref as any}
          onClick={onClick}
          readOnly
          autoComplete="off"
          autoCorrect="off"
          parentStyles={StyledInputParent(size)}
          spellCheck="false"
          data-testid="search-input-box-fake"
        />
      </SearchInputWrapper>
    )
  },
)
