import type { JsonRpcSigner } from '@ethersproject/providers'

import { PublicENS, Transaction, TransactionDisplayItem } from '@app/types'
import { shortenAddress } from '@app/utils/utils'

type Data = {
  name: string
  data: string
  to: string
  from: string
  address: string
}

const displayItems = ({ name, address }: Data): TransactionDisplayItem[] => [
  {
    label: 'reclaim',
    value: name.length > 30 ? shortenAddress(name, 30, 10, 10) : name,
    type: 'name',
  },
  {
    label: 'to',
    value: address,
    type: 'address',
  },
]

const transaction = async (signer: JsonRpcSigner, ens: PublicENS, data: Data) => {
  return Promise.resolve({ data: data.data, to: data.to, from: data.from })
}

export default { displayItems, transaction } as Transaction<Data>
