export enum UseScenes {
  register = '0',
  renewal = '1',
  notLimited = '-1',
}
export const ScenesTitle: { [k in UseScenes]: string } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '-1': '',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '0': '*Discount only applies to the registration fee (maximum 3 years)',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '1': '*Discount applies to renewal fee.',
}
