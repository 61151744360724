/* eslint-disable @typescript-eslint/naming-convention */
import ChainBaseLogo from '@app/assets/chain-base.svg'
import ChainEthLogo from '@app/assets/chain-eth.svg'
import { ENV_NAME } from '@app/utils/constants'
import { ChainMap } from '@app/utils/query'

export const ENVChainList = ENV_NAME === 'dev' ? [ChainMap[5]] : [ChainMap[1], ChainMap[8453]]
export const ENVChainIds = ENVChainList.map((i) => i.id)
export const ENVChainIdMappingDomain = {
  1: ENV_NAME === 'pre' ? 'https://pre-awns.netlify.app' : 'https://awns.stp.network',
  8453:
    ENV_NAME === 'pre'
      ? 'https://base-chain-pre--pre-awns.netlify.app'
      : 'https://awnsbase.stp.network',
}
export const ChainLogo = {
  1: <ChainEthLogo />,
  5: <ChainEthLogo />,
  11155111: <ChainEthLogo />,
  8453: <ChainBaseLogo />,
}
