import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { ENVChainIdMappingDomain, ENVChainIds } from '@app/constants/chain'
import { useChainId } from '@app/hooks/useChainId'
// import usePrevious from '@app/hooks/usePrevious'
import { SUPPORT_NETWORK_CHAIN_IDS } from '@app/utils/constants'

function CheckChain() {
  const chainId = useChainId()
  const { asPath } = useRouter()
  if (chainId && !SUPPORT_NETWORK_CHAIN_IDS.includes(chainId) && ENVChainIds.includes(chainId)) {
    const domain = ENVChainIdMappingDomain[chainId as keyof typeof ENVChainIdMappingDomain]
    if (window) {
      window.location.href = domain + asPath
    }
  }
  return null
}

export default function ResetWebsiteOfChain({ children }: { children: JSX.Element }) {
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setTimeout(() => setChecked(true), 1200)
  }, [])

  return (
    <>
      {checked ? <CheckChain /> : null}
      {children}
    </>
  )
}
