import { useMemo } from 'react'

import { useGetUserImg } from './requst/useGetUserImg'

export const useGetAvatar = (name: string, readCache = false) => {
  let localAvatar = ''

  if (readCache) {
    try {
      localAvatar = localStorage.getItem(`avatar-src-${name}`) || ''
    } catch (error) {
      localAvatar = ''
    }
  }

  const { avatarSrc } = useGetUserImg(readCache && localAvatar ? '' : name)

  return useMemo(() => {
    return localAvatar || avatarSrc || ''
  }, [avatarSrc, localAvatar])
}
