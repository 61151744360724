import { Box, Stack, Typography, styled } from '@mui/material'
import Head from 'next/head'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchInput } from '@app/components/@molecules/SearchInput/SearchInput'
import { LeadingHeading } from '@app/components/LeadingHeading'
import Game from '@app/components/pages/Home/game'
// import Card from '@app/components/pages/Home/card'
import { useBreakpoint } from '@app/utils/BreakpointProvider'

// import ENSFull from '../assets/ENSFull.svg'
// import StpLogo from '../assets/StpLogo.svg'

// const GradientTitle = styled.h1(
//   ({ theme }) => css`
//     font-size: ${theme.fontSizes.headingTwo};
//     text-align: center;
//     font-weight: 800;
//     background-image: ${theme.colors.gradients.accent};
//     background-repeat: no-repeat;
//     background-size: 110%;
//     /* stylelint-disable-next-line property-no-vendor-prefix */
//     -webkit-background-clip: text;
//     background-clip: text;
//     color: transparent;
//     margin: 0;

//     ${mq.sm.min(css`
//       font-size: ${theme.fontSizes.headingOne};
//     `)}
//   `,
// )

// const SubtitleWrapper = styled.div(
//   ({ theme }) => css`
//     max-width: calc(${theme.space['72']} * 2 - ${theme.space['4']});
//     line-height: 150%;
//     text-align: center;
//     margin-bottom: ${theme.space['3']};
//   `,
// )

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: '1',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginTop: '60px',
  },
}))

export const StyledENS = styled(Box)(() => ({
  height: 'auto',
}))

export const LogoAndLanguage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 10,
  flexGap: 10,
}))

export const StyledLeadingHeading = styled(LeadingHeading)(({ theme }) => ({
  margin: 0,
  width: '100%',
  padding: '10px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyleTitle = styled(Typography)(({ theme }) => ({
  fontSize: '48px',
  fontWeight: 700,
  maxWidth: '150px',
  lineHeight: '58px',
  background: 'linear-gradient(107.41deg, #0049C6 23%, #A7F46A 100%)',
  boxShadow: '0 1px 0 0 #00000012',
  backgroundClip: 'text',
  color: 'transparent',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    fontSize: 28,
    lineHeight: '34px',
  },
}))

const BoldTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '39px',
  background: 'linear-gradient(107.41deg, #FFFFFF 65%, rgba(255, 255, 255, 0.43) 100%)',
  boxShadow: '0 1px 0 0 #00000012',
  backgroundClip: 'text',
  color: 'transparent',
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
    lineHeight: '29px',
  },
}))

const SmallTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: 'Inter',
  lineHeight: '29px',
  fontWeight: 500,
  background: '#BDBDC0 ',
  backgroundClip: 'text',
  color: 'transparent',
  paddingBottom: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    lineHeight: '19px',
  },
}))

// const BGBox1 = styled(Box)`
//   background: url('/bg1.png');
//   background-repeat: no-repeat;
//   background-position: center;
//   position: absolute;
//   width: 720px;
//   height: 676px;
//   left: 50%;
//   transform: translateX(-50%);
//   top: 10%;
//   z-index: 1;
// `
// const BGBox2 = styled(Box)`
//   width: 720px;
//   height: 676px;
//   background: url('/bg2.png');
//   background-repeat: no-repeat;
//   background-position: center;
//   position: absolute;
//   left: 50%;
//   top: 15%;
//   transform: translateX(-50%);
//   z-index: 1;
// `

const StyleContentBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundImage: `url(/home-bg.png)`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  minHeight: '80vh',
  maxHeight: 876,
  position: 'relative',
  zIndex: 9,
  marginTop: -20,
  [theme.breakpoints.down('md')]: {
    height: 390,
    minHeight: 'auto',
    marginTop: '0',
    backgroundSize: 'contain',
    backgroundImage: `none`,
    gap: '8px',
  },
}))

const StyleMobileTopBox = styled(Box)(() => ({
  width: '100%',
  backgroundImage: `url(/home-bg.png)`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}))

const Image = styled('img')(() => ({}))

export default function Page() {
  const { t } = useTranslation('awns_common')
  // const [showBg2, setShowBg2] = useState(true)
  const breakpoint = useBreakpoint()

  const isMdDown = useMemo(() => {
    return !breakpoint.md
  }, [breakpoint])

  if (isMdDown) {
    return (
      <>
        <Head>
          <title>AWNS</title>
        </Head>
        <Container>
          <StyleMobileTopBox>
            {/* <Typography
              sx={{
                background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%)',
                boxShadow: '0 4px 24px 0 #888dff00',
                backgroundClip: 'text',
                color: 'transparent',
                fontFamily: 'Inter',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '39px',
              }}
            >
              Game Portal
            </Typography> */}

            <StyleContentBox>
              <Box>
                <Box
                  sx={{
                    backgroundColor: '#000228',
                    width: '100%',
                    mb: '20px',
                  }}
                >
                  <StyleTitle>{t('title')}</StyleTitle>
                </Box>
                <Stack
                  spacing="8px"
                  sx={{
                    maxWidth: '270px',
                    margin: '0 auto',
                  }}
                >
                  <BoldTitle>{t('title_name')}</BoldTitle>
                  <SmallTitle>{t('business')}</SmallTitle>
                </Stack>
                <SearchInput />
              </Box>
            </StyleContentBox>
          </StyleMobileTopBox>

          <Game />

          <Image
            src="/home/home-bottom-bg.png"
            width="100%"
            height="448px"
            sx={{
              position: 'absolute',
              zIndex: 0,
              bottom: 0,
            }}
          />
        </Container>
      </>
    )
  }
  return (
    <>
      <Head>
        <title>AWNS</title>
      </Head>
      <Container>
        <StyleContentBox>
          {/* <Typography
            sx={{
              background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
              boxShadow: '0 4px 24px 0 #888dff00',
              backgroundClip: 'text',
              color: 'transparent',
              fontFamily: 'Inter',
              fontSize: 64,
              fontWeight: 500,
              position: 'relative',
              zIndex: 9,
            }}
          >
            Game Portal
          </Typography> */}

          <Stack
            spacing="16px"
            justifyContent="center"
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              width: { xs: 'calc(100vw - 32px)', md: 600 },
            }}
          >
            <StyleTitle>{t('title')}</StyleTitle>
            <BoldTitle>{t('title_name')}</BoldTitle>
            <SmallTitle>{t('business')}</SmallTitle>
            {/* <SearchInput setBgShow={(v: boolean) => setShowBg2(v)} /> */}
            <SearchInput />
          </Stack>
        </StyleContentBox>
        {/* <Image
          src="/title-bg.png"
          width="100%"
          height="100%"
          sx={{
            position: 'absolute',
            mixBlendMode: 'plus-lighter',
            pointerEvents: 'none',
            top: -140,
            height: 528,
            zIndex: 1,
            opacity: 0.5,
          }}
        /> */}
        <Game />

        <Image
          src="/home/home-bottom-bg.png"
          width="100%"
          height="448px"
          sx={{
            position: 'absolute',
            bottom: 0,
          }}
        />
        {/* {breakpoint.sm && (
          <>
            <BGBox1 />
            {showBg2 && <BGBox2 />}
          </>
        )} */}
        {/* {showBg2 && <Card />} */}
      </Container>
    </>
  )
}
