import { createMulticall } from '@uniswap/redux-multicall'
import { useBlockNumber } from 'wagmi'

import { useInterfaceMulticall } from '@app/hooks/useContract'
import { SUPPORT_NETWORK_CHAIN_IDS } from '@app/utils/constants'

const multicall = createMulticall()

export default multicall

export function MulticallUpdaterSingle({ chainId }: { chainId: number }) {
  const { data: latestBlockNumber } = useBlockNumber({ chainId })
  const contract = useInterfaceMulticall(chainId)
  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
    />
  )
}

export function MulticallUpdater() {
  return (
    <>
      {SUPPORT_NETWORK_CHAIN_IDS.map((chainId) => (
        <MulticallUpdaterSingle key={chainId} chainId={chainId} />
      ))}
    </>
  )
}
