/* eslint-disable @typescript-eslint/naming-convention */
import { Box, ButtonBase, Stack, Typography, styled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { useBreakpoint } from '@app/utils/BreakpointProvider'

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexGrow: '1',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  paddingBottom: '120px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    paddingBottom: '80px',
    marginTop: '20px',
  },
}))

const StyleBanner = styled(Box)(({ theme }) => ({
  maxWidth: '1064px',
  width: '100%',
  height: '548px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}))

const StyleBannerTitle = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  lineHeight: '58px',
  fontWeight: 600,
  background: 'linear-gradient(107.41deg, #FFFFFF 65%, rgba(255, 255, 255, 0.43) 100%)',
  boxShadow: '0 1px 0 0 #00000012',
  backgroundClip: 'text',
  color: 'transparent',
  [theme.breakpoints.down('md')]: {
    fontSize: 28,
  },
}))

const StyleBannerBox = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'start',
    gap: '24px',
  },
}))

const StyleBanners = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  maxHeight: 450,
  width: 224,
  overflowY: 'auto',
  gap: '20px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%',
    overflowY: 'unset',
    overflowX: 'auto',
    gap: '8px',
    padding: '0 0 0 8px',
  },
}))

const StyleSmallCard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '135px',
  padding: 8,
  gap: '8px',
  borderRadius: '16px',
  border: '1px solid #363636',
  background: '#1D1C20',
  flexShrink: 0,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    maxWidth: '142px',
    height: '80px',
    padding: 4,
  },
}))

const StyleBannerCard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 810,
  maxHeight: 450,
  padding: 16,
  borderRadius: '20px',
  border: '1px solid #363636',
  background: '#1D1C20',
  flexShrink: 0,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 32px)',
    margin: 'auto',
    padding: 8,
  },
}))

const StyleButton = styled(ButtonBase)(({ theme }) => ({
  width: '116px',
  height: 32,
  padding: '8px 24px',
  borderRadius: '32px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '15px',
  color: '#fff',
  background: '#0049C6',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    padding: '4px 16px',
    fontSize: '10px',
    height: 20,
    width: '90px',
  },
}))

const Image = styled('img')(() => ({}))

const Link = styled('a')(() => ({}))

interface BannerProps {
  avatar: string
  name: string
  content: string
  link: string
  bannerUrl: string
  id: number
  isSoon?: boolean
}

const gameList: BannerProps[] = [
  {
    id: 0,
    avatar: '/home/game/avatar-game3.png',
    name: 'Eternal Legacy',
    content:
      'Riveting AI-powered card game where you uniquely build a deck generated by your own NFTs to battle friends and foes in the ultimate onchain strategy game.',
    link: 'https://eternallegacy.xyz',
    // isSoon: true,
    bannerUrl: '/home/game/banner-game3.png',
  },
  {
    id: 1,
    avatar: '/home/game/avatar-game1.gif',
    name: 'Ancient Forest',
    content:
      'Survive a story-based onchain game revolving around resource gathering and base building!',
    link: 'https://ancientforest.xyz/',
    bannerUrl: '/home/game/banner-game1.png',
  },
  {
    id: 2,
    avatar: '/home/game/avatar-game2.png',
    name: 'Dice Game',
    content:
      'Simple yet extremely fun dice-rolling game, complete 6 rolls to get a bonus or reward!',
    link: 'https://lootdice.xyz/',
    bannerUrl: '/home/game/banner-game2.png',
  },
]

const Game = () => {
  const [bannerItem, setBannerItem] = useState<BannerProps>(gameList[0])
  const breakpoint = useBreakpoint()

  useEffect(() => {
    const itemIntervalFunc = () => {
      const indexMax = gameList.length - 1
      if (bannerItem.id >= indexMax) {
        setBannerItem(gameList[0])
      } else {
        setBannerItem(gameList[bannerItem.id + 1])
      }
    }
    const int = window.setInterval(itemIntervalFunc, 4000)
    return () => {
      window.clearInterval(int)
    }
  }, [bannerItem.id])

  const isMdDown = useMemo(() => {
    return !breakpoint.md
  }, [breakpoint])

  if (isMdDown) {
    return (
      <Container>
        <StyleBanner>
          <StyleBannerTitle>Gaming Console</StyleBannerTitle>
          <StyleBannerBox>
            <StyleBannerCard>
              <Image
                src={bannerItem.bannerUrl}
                width="100%"
                height="100%"
                style={{
                  pointerEvents: 'none',
                  borderRadius: '10px',
                }}
              />
              <Stack
                spacing="6px"
                sx={{
                  width: 'calc(100% - 16px)',
                  height: 'max-content',
                  minHeight: '60px',
                  background: '#0000004D',
                  borderRadius: '0 0 10px 10px',
                  padding: '4px 10px 6px',
                  position: 'absolute',
                  bottom: '16px',
                }}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack
                    sx={{
                      gap: '8px',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      src={bannerItem.avatar}
                      width="16px"
                      height="16px"
                      style={{
                        borderRadius: '8px',
                        pointerEvents: 'none',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: '17px',
                        color: '#fff',
                      }}
                    >
                      {bannerItem.name}
                    </Typography>
                  </Stack>
                  {bannerItem.isSoon ? (
                    <StyleButton
                      disabled
                      sx={{
                        backgroundColor: '#999',
                      }}
                    >
                      Coming Soon
                    </StyleButton>
                  ) : (
                    <Link href={bannerItem.link} target="_blank">
                      <StyleButton>Play Beta</StyleButton>
                    </Link>
                  )}
                </Stack>
                <Typography
                  sx={{
                    width: '100%',
                    wordWrap: 'break-word',
                    fontFamily: 'Inter',
                    fontSize: { xs: '10px', md: '16px' },
                    fontWeight: 500,
                    lineHeight: { xs: '12px', md: '19px' },
                    letterSpacing: '-0.02em',
                    padding: { xs: '0 24px', md: '0 40px' },
                    color: '#B2B2B2',
                  }}
                >
                  {bannerItem.content}
                </Typography>
              </Stack>
            </StyleBannerCard>
            <StyleBanners>
              {gameList.map((item) => (
                <StyleSmallCard
                  key={item.name}
                  onClick={() => setBannerItem(item)}
                  sx={{
                    opacity: bannerItem?.id === item.id ? 1 : 0.5,
                  }}
                >
                  <Image
                    src={item.bannerUrl}
                    width="100%"
                    height="100%"
                    style={{
                      pointerEvents: 'none',
                      borderRadius: '10px',
                    }}
                  />
                </StyleSmallCard>
              ))}
            </StyleBanners>
          </StyleBannerBox>
        </StyleBanner>
      </Container>
    )
  }

  return (
    <Container>
      <StyleBanner>
        <StyleBannerTitle>Gaming Console</StyleBannerTitle>
        <StyleBannerBox>
          <StyleBanners>
            {gameList.map((item) => (
              <StyleSmallCard
                key={item.name}
                onClick={() => setBannerItem(item)}
                sx={{
                  opacity: bannerItem?.id === item.id ? 1 : 0.5,
                }}
              >
                <Image
                  src={item.bannerUrl}
                  width="100%"
                  height="100%"
                  style={{
                    pointerEvents: 'none',
                    borderRadius: '10px',
                  }}
                />
              </StyleSmallCard>
            ))}
          </StyleBanners>
          <StyleBannerCard>
            <Image
              src={bannerItem.bannerUrl}
              width="100%"
              height="100%"
              style={{
                pointerEvents: 'none',
                borderRadius: '10px',
              }}
            />
            <Stack
              spacing="8px"
              sx={{
                width: 'calc(100% - 32px)',
                height: '100%',
                maxHeight: '123px',
                background: '#0000004D',
                borderRadius: '0 0 10px 10px',
                padding: '30px 25px',
                position: 'absolute',
                bottom: '16px',
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Stack
                  sx={{
                    gap: '8px',
                    flexDirection: 'row',
                  }}
                >
                  <Image
                    src={bannerItem.avatar}
                    width="32px"
                    height="32px"
                    style={{
                      borderRadius: '8px',
                      pointerEvents: 'none',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 500,
                      lineHeight: '29px',
                      color: '#fff',
                    }}
                  >
                    {bannerItem.name}
                  </Typography>
                </Stack>
                {bannerItem.isSoon ? (
                  <StyleButton
                    disabled
                    sx={{
                      backgroundColor: '#999',
                    }}
                  >
                    Coming Soon
                  </StyleButton>
                ) : (
                  <Link href={bannerItem.link} target="_blank">
                    <StyleButton>Play Beta</StyleButton>
                  </Link>
                )}
              </Stack>
              <Typography
                sx={{
                  width: '100%',
                  wordWrap: 'break-word',
                  fontFamily: 'Inter',
                  fontSize: { xs: '10px', md: '16px' },
                  fontWeight: 500,
                  lineHeight: { xs: '12px', md: '19px' },
                  letterSpacing: '-0.02em',
                  padding: { xs: '0 24px', md: '0 0 0 40px' },
                  color: '#B2B2B2',
                }}
              >
                {bannerItem.content}
              </Typography>
            </Stack>
          </StyleBannerCard>
        </StyleBannerBox>
      </StyleBanner>
    </Container>
  )
}

export default Game
