import { useNetwork } from 'wagmi'

import { SUPPORT_NETWORK_CHAIN_IDS, networkName } from '@app/utils/constants'

export const useChainName = () => {
  const { chain } = useNetwork()
  const defaultChain = networkName[SUPPORT_NETWORK_CHAIN_IDS[0].toString()]
  if (chain) {
    if (!chain.network) throw new Error('Chain network is not defined')
    const name = chain.network.toLowerCase()
    return name === 'homestead' ? 'mainnet' : name
  }
  return defaultChain
}
